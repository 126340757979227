<script setup>
import { ref } from "vue";
import http from "axios";
const tipRows = ref([]);

setInterval(getTips, 3000);

async function getTips() {
  try {
    const resp = await http.get(process.env.VUE_APP_API_URL + "getTips");
    tipRows.value = resp.data.tipRows;
  } catch (err) {
    console.log(err);
  }
}

function formatNumber(Amount) {
  parseFloat(Amount);
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Amount);
}
</script>

<template>
  <div class="row g-0">
    <div class="col-12">
      <table
        class="table table-sm table_custom table-dark fs-2"
        id="request-table"
      >
        <thead>
          <tr style="background: rgba(71, 71, 71, 0.8)" class="fw-semibold">
            <th scope="col" class="fw-normal">Amount</th>
            <th scope="col" class="text-nowrap text-end fw-normal">
              Tipped By
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tipRow, index) in tipRows" :key="'tip-' + index">
            <td>${{ formatNumber(tipRow["t_amt"]) }}</td>
            <td class="text-end">{{ tipRow["t_name"] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.table_custom {
  --bs-table-bg: transparent !important;
}

.table {
  width: 100%;
  border-collapse: separate; /* boom */
  border-spacing: 0 4px; /* bam */
}

.table td {
  background-color: rgba(71, 71, 71, 0.5);
  border: 5px solid transparent;
}

.currentReq {
  background-color: rgba(71, 71, 71, 0.7);
}
</style>
