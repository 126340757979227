import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "aos/dist/aos.css";
import VueMatomo from "vue-matomo";

const axiosInstance = axios;

const app = createApp(App);
app.use(router);
if (process.env.NODE_ENV == "production") {
  app.use(VueMatomo, {
    host: "https://stats.guestqueue.com",
    siteId: 2,
    trackerFileName: "matomo",
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    domains: undefined,
    cookieDomain: undefined,
    preInitActions: [],
  });
  window._paq.push(["setCookieSameSite", "None"]);
}
app.config.globalProperties.$http = { ...axiosInstance };
app.mount("#app");
