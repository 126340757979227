<template>
  <div class="player-container">
    <video ref="videoPlayer" class="video-js"></video>
    <div class="vjs-playlist">
      <!--
            The contents of this element will be filled based on the
            currently loaded playlist
        -->
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import "videojs-playlist/dist/videojs-playlist.js";
import "videojs-playlist-ui/dist/videojs-playlist-ui.css";
import "videojs-playlist-ui/dist/videojs-playlist-ui.vertical.css";
import "videojs-playlist-ui/dist/videojs-playlist-ui.js";
import "videojs-event-tracking/dist/videojs-event-tracking.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    playlist: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      //this.player.log("onPlayerReady", this);
    });

    this.player.playlist(this.playlist);
    this.player.playlistUi({ horizontal: true });
    this.player.eventTracking();
    this.player.on("tracking:firstplay", () => {
      if (process.env.NODE_ENV == "production") {
        window._paq.push(["trackEvent", "Video", "Played"]);
      }
    });
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style>
.vjs-playlist {
  height: 100px;
}
</style>
