<script setup>
import { computed, ref } from "vue";
//import ccForm from "@/components/CreditCardForm.vue";
import * as Yup from "yup";
import { Form, Field } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import http from "axios";

const tipSet = ref(false);
let apiURL = process.env.VUE_APP_API_URL;
const route = useRoute();
const router = useRouter();
const twitchName = ref("Anonymous");
const tipAmount = ref(5);
const message = ref("");
const alert = ref(false);
const alertError = ref(false);
const alertMessage = ref("");
const hideform = ref(false);
const customAmount = ref();

const schema = computed(() => {
  var ccRules = {
    twitchName: Yup.string().required("Name is required").trim(),
  };

  var tipRule =
    tipAmount.value == 0
      ? {
          customAmount: Yup.number()
            .min(5, "Tip amount must greater than or equal to 5")
            .required("Tip amount is required "),
        }
      : [];

  var rules = {
    ...ccRules,
    ...tipRule,
  };

  return Yup.object().shape(rules);
});

if (route.params.sessionID) {
  savePayment();
}

const stripe = Stripe(process.env.VUE_APP_STRIPE_PK);

// Create a Checkout Session
async function setTip() {
  let formData = new FormData();
  var amount = tipAmount.value > 0 ? tipAmount.value : customAmount.value;
  formData.append("tipAmount", amount);
  formData.append("twitchName", twitchName.value);
  formData.append("message", message.value);
  const fetchClientSecret = async () => {
    const response = await fetch(apiURL + "setSession", {
      method: "POST",
      body: formData,
    });
    const { clientSecret } = await response.json();
    return clientSecret;
  };

  const checkout = await stripe.initEmbeddedCheckout({
    fetchClientSecret,
  });

  // Mount Checkout
  checkout.mount("#checkout");
}

function setTipAmount() {
  tipSet.value = true;
  setTip();
}

async function savePayment() {
  let form = new FormData();
  form.append("sessionID", route.params.sessionID);
  hideform.value = true;
  try {
    const resp = await http.post(process.env.VUE_APP_API_URL + "saveTip", form);
    alert.value = true;
    alertMessage.value = "Thank you for the $" + resp.data.amount + " tip!";
    if (process.env.NODE_ENV == "production") {
      window._paq.push([
        "trackEvent",
        "Tip",
        "Tip submitted " + resp.data.amount,
      ]);
    }
  } catch (err) {
    alert.value = true;
    alertError.value = true;
    alertMessage.value = err.response.data.message;
  }
}

function resetForm() {
  window.location.replace("/tip");
}
</script>

<template>
  <div class="container my-4">
    <div class="row mb-3">
      <div class="col-8">
        <h1 class="h4">
          Tip <span v-if="!tipSet && !hideform">amount</span
          ><span v-else>DJ Saturn</span>
        </h1>
      </div>
      <div class="col-4 text-end">
        <a
          href="#"
          class="btn btn-primary"
          @click.prevent="resetForm"
          v-if="tipSet"
          >Start over</a
        >
      </div>
    </div>
    <div
      class="alert"
      :class="alertError ? 'alert-danger' : 'alert-success'"
      role="alert"
      v-if="alert"
      v-html="alertMessage"
    ></div>
    <div v-if="!hideform">
      <Form
        @submit="setTipAmount"
        :validation-schema="schema"
        v-slot="{ errors }"
        v-show="!tipSet"
      >
        <div class="row mb-3">
          <div class="col-md-12">
            <input
              type="radio"
              class="btn-check"
              name="tipAmount"
              id="five"
              autocomplete="off"
              v-model="tipAmount"
              value="5"
            />
            <label class="btn" for="five">$5.00</label>
            <input
              type="radio"
              class="btn-check"
              name="tipAmount"
              id="ten"
              autocomplete="off"
              v-model="tipAmount"
              value="10"
            />
            <label class="btn" for="ten">$10.00</label>
            <input
              type="radio"
              class="btn-check"
              name="tipAmount"
              id="fifteen"
              autocomplete="off"
              v-model="tipAmount"
              value="15"
            />
            <label class="btn" for="fifteen">$15.00</label>
            <input
              type="radio"
              class="btn-check"
              name="tipAmount"
              id="twenty"
              autocomplete="off"
              v-model="tipAmount"
              value="20"
            />
            <label class="btn" for="twenty">$20.00</label>
            <input
              type="radio"
              class="btn-check"
              name="tipAmount"
              id="other"
              autocomplete="off"
              v-model="tipAmount"
              value="0"
            />
            <label class="btn" for="other">Other</label>
          </div>
        </div>
        <div
          v-if="tipAmount == 0"
          class="form-floating mb-3"
          :class="{ 'is-invalid': errors.customAmount }"
        >
          <Field
            type="text"
            class="form-control"
            name="customAmount"
            id="custom-amount"
            v-model="customAmount"
            placeholder="Tip amount"
            aria-label="Tip amount"
            :class="{ 'is-invalid': errors.customAmount }"
          />
          <label for="custom-amount">Custom amount</label>
          <div class="invalid-feedback">{{ errors.customAmount }}</div>
        </div>
        <div
          class="form-floating mb-3"
          :class="{ 'is-invalid': errors.twitchName }"
        >
          <Field
            type="text"
            class="form-control"
            name="twitchName"
            id="twitch-name"
            v-model="twitchName"
            placeholder="Name for tip"
            aria-label="Name for tip"
            :class="{ 'is-invalid': errors.twitchName }"
          />
          <label for="twitch-name">Your name</label>
          <div class="invalid-feedback">{{ errors.twitchName }}</div>
        </div>
        <div class="form-floating">
          <textarea
            class="form-control mb-3"
            placeholder="Message"
            id="tipMessage"
            style="height: 100px"
            v-model="message"
            maxlength="255"
          ></textarea>
          <label for="tipMessage">Leave a message with your tip</label>
        </div>
        <button type="submit" class="btn btn-primary btn-lg">Set Tip</button>
      </Form>
      <div id="checkout" v-show="tipSet">
        <!-- Checkout will insert the payment form here -->
      </div>
    </div>
  </div>
</template>
