<template>
  <div class="container pt-5 pb-5 mb-5 mt-5">
    <h2>Page not found</h2>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
