<script>
import AOS from "aos";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

export default {
  name: "DjSatuRn",
  components: {
    VideoPlayer,
    Form,
    Field,
  },
  mounted() {
    AOS.init({
      duration: 800,
      once: true,
    });
    new Twitch.Embed("twitch-embed", {
      width: "100%",
      height: 480,
      channel: "djsaturn_",
    });
  },
  data() {
    return {
      message: "",
      yourTitle: "",
      nodeEnv: process.env.NODE_ENV,
      alertError: false,
      alertMessage: "",
      alert: false,
      loading: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        fluid: true,
        preload: "metadata",
        eventTracking: true,
      },
      schema: Yup.object().shape({
        name: Yup.string().required("Name is required").trim(),
        email: Yup.string()
          .required("Email is required")
          .email("Email is invalid")
          .trim(),
        message: Yup.string().required("Message is required").trim(),
      }),
      playlist: [
        {
          sources: [
            {
              src: "https://d1wx9pshsk2zdp.cloudfront.net/video-mixes/DjSatuRnReggaetonCumbia20230116.mp4",
              type: "video/mp4",
            },
          ],
          description: "",
          poster: "../assets/images/djsaturn-02.jpg",
          thumbnail: [
            {
              srcset: "../assets/images/djsaturn-02.jpg",
              type: "image/png",
              media: "(max-width: 125px;)",
            },
            {
              src: "../assets/images/djsaturn-02.jpg",
            },
          ],
          name: "Reggaeton Cumbia 2023 - 01",
          duration: 1272,
        },
        {
          sources: [
            {
              src: "https://d1wx9pshsk2zdp.cloudfront.net/video-mixes/HouseMix20221228.mp4",
              type: "video/mp4",
            },
          ],
          description: "",
          poster: "../assets/images/djsaturn-02.jpg",
          thumbnail: [
            {
              srcset: "../assets/images/djsaturn-albumart.png",
              type: "image/png",
              media: "(max-width: 125px;)",
            },
            {
              src: "../assets/images/djsaturn-albumart.png",
            },
          ],
          name: "House Mix 2022 - 12",
          duration: 1419,
        },
        {
          sources: [
            {
              src: "https://d1wx9pshsk2zdp.cloudfront.net/video-mixes/LatinMix20221127.mp4",
              type: "video/mp4",
            },
          ],
          description: "",
          poster: "../assets/images/djsaturn-02.jpg",
          thumbnail: [
            {
              srcset: "../assets/images/djsaturn-02.jpg",
              type: "image/png",
              media: "(max-width: 125px;)",
            },
            {
              src: "../assets/images/djsaturn-02.jpg",
            },
          ],
          name: "Latin Mix 2022 - 11",
          duration: 1505,
        },
        {
          sources: [
            {
              src: "https://d1wx9pshsk2zdp.cloudfront.net/video-mixes/DanceMix20221001.mp4",
              type: "video/mp4",
            },
          ],
          description: "",
          poster: "../assets/images/djsaturn-02.jpg",
          name: "Dance Mix 2022-10",
          duration: 1314,
          thumbnail: [
            {
              srcset: "../assets/images/djsaturn-albumart.png",
              type: "image/png",
              media: "(max-width: 125px;)",
            },
            {
              src: "../assets/images/djsaturn-albumart.png",
            },
          ],
        },
        {
          sources: [
            {
              src: "https://d1wx9pshsk2zdp.cloudfront.net/video-mixes/DjSatuRn_Demo_Mix0509.mp4",
              type: "video/mp4",
            },
          ],
          thumbnail: [
            {
              srcset: "../assets/images/djsaturn-02.jpg",
              type: "image/jpg",
              media: "(max-width: 125px;)",
            },
            {
              src: "../assets/images/djsaturn-02.jpg",
            },
          ],
          name: "Various Mix 2009 - 05",
          duration: 1355,
        },
      ],
    };
  },
  methods: {
    async onSubmit(values, { resetForm }) {
      this.alertError = false;
      this.alert = false;
      this.alertMessage = "";
      this.loading = true;
      let form = new FormData();

      let phone = values.phone ? values.phone : "";
      form.append("name", values.name);
      form.append("email", values.email);
      form.append("yourTitle", this.yourTitle);
      form.append("phone", phone);
      form.append("message", values.message);

      try {
        const resp = await this.$http.post(
          process.env.VUE_APP_API_URL + "sendemail",
          form
        );
        this.alert = true;
        this.alertMessage = resp.data.message;
        if (process.env.NODE_ENV == "production") {
          window._paq.push(["trackEvent", "Booking", "Inquiry submitted"]);
        }
        resetForm();
      } catch (err) {
        this.alert = true;
        this.alertError = true;
        this.alertMessage = err.response.data.message;
      }
      this.loading = false;
    },
    trackEvent(event) {
      if (this.nodeEnv == "production") {
        window._paq.push(["trackEvent", "link", event]);
      }

      return true;
    },
  },
};
</script>

<template>
  <header
    class="bg-dark py-5 bg-img-start img-fluid"
    style="background-image: url(../assets/images/lightingbg.jpg)"
  >
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-8 col-xl-7 col-xxl-6">
          <div class="my-5 text-center text-xl-start">
            <h1
              class="display-5 fw-bolder text-white mb-2"
              style="letter-spacing: 0.4rem"
              data-aos="flip-right"
            >
              Nightclub DJ
            </h1>
            <p
              class="lead fw-normal text-white mb-4"
              style="text-shadow: 2px 2px #000"
              data-aos="zoom-out"
            >
              An open format DJ specializing in nightclubs. Over 15 years of
              experience, playing in front of over 1500 people.
            </p>
            <div
              class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start"
            >
              <a
                class="btn btn-primary btn-lg px-4 me-sm-3"
                href="#contact"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                >Bookings</a
              >
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
          <img
            class="img-fluid rounded-3 my-5"
            src="assets/images/djsaturnmix.jpg"
            alt="..."
          />
        </div>
      </div>
    </div>
  </header>
  <div class="bg-purple p-3">
    <div class="container">
      <div class="row justify-content-lg-between align-items-lg-center">
        <div class="col-md pb-4">
          <h2 class="text-white mb-0 text-center">
            Live streaming on Twitch! Follow for notifications.
          </h2>
        </div>
      </div>
      <div class="d-grid col-md-6 col-sm-12 mx-auto">
        <a
          class="btn btn-light text-bg-light fw-medium"
          href="https://www.twitch.tv/djsaturn_"
          target="_blank"
          @click="trackEvent('Twitch')"
          ><i class="bi bi-twitch"></i> Follow on Twitch</a
        >
      </div>
    </div>
  </div>
  <div class="container mt-4">
    <div class="py-4" id="twitch-embed"></div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h1 class="text-center pb-3" id="videoMixes" data-aos="fade-up">
          Live Video Mixes (No Sync)
        </h1>
        <video-player :options="videoOptions" :playlist="playlist" />
      </div>
      <p class="text-white mt-3 fw-bold text-center">
        Stay tuned for new video mixes each month
      </p>
    </div>
    <div class="row justify-content-lg-between mt-2 mb-5">
      <div class="col-lg-6" data-aos="zoom-in">
        <!-- Info -->
        <h1 id="contact">About DJ Saturn</h1>
        <p class="text-white-70">
          Professional video DJ, rocking audiences of all types. Specializing in
          Top 40, Hip Hop, Dance, Electro, Rock En Español, 80s, Guaracha,
          Reggaeton, Cumbia, Merengue and Salsa. Saturn was the resident DJ at
          the Mayan in Los Angeles for about 12 years.
        </p>
        <!-- End Info -->

        <div
          class="border-top border-white-10 my-4"
          style="max-width: 15rem"
        ></div>
        <p class="text-white-70">
          DJ Saturn has the ability to program, create flow and balance at a
          nightclub. He also can play at various times of the night, from
          opening, peak hour and closing times. His primary focus is getting
          people on the dance floor and balancing energy throughout the night.
        </p>
      </div>

      <div class="col-lg-6 text-dark" data-aos="fade-right">
        <!-- Card -->
        <div class="card card-lg bg-dark border-light">
          <div class="card-body">
            <div class="mb-4">
              <h3 class="card-title text-white">Booking inquiry.</h3>
            </div>
            <div
              class="alert"
              :class="alertError ? 'alert-danger' : 'alert-success'"
              role="alert"
              v-if="alert"
              v-html="alertMessage"
            ></div>

            <!-- Form -->
            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
            >
              <div
                class="form-floating mb-3"
                :class="{ 'is-invalid': errors.name }"
              >
                <Field
                  type="text"
                  class="form-control"
                  name="name"
                  id="name"
                  placeholder="Name"
                  aria-label="Name"
                  :class="{ 'is-invalid': errors.name }"
                />
                <label for="name">Name</label>
                <div class="invalid-feedback">{{ errors.name }}</div>
              </div>
              <!-- End Form -->

              <div class="row">
                <div class="col-sm-6">
                  <!-- Form -->
                  <div
                    class="form-floating mb-3"
                    :class="{ 'is-invalid': errors.email }"
                  >
                    <Field
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="email@site.com"
                      aria-label="email@site.com"
                      :class="{ 'is-invalid': errors.email }"
                    />
                    <label for="email">Email address</label>
                    <div class="invalid-feedback">{{ errors.email }}</div>
                  </div>
                  <!-- End Form -->
                </div>
                <!-- End Col -->

                <div class="col-sm-6">
                  <!-- Form -->
                  <div class="form-floating mb-3">
                    <Field
                      type="text"
                      class="form-control"
                      name="phone"
                      id="phone"
                      placeholder="(213) 555-5555"
                      aria-label="phone"
                    />
                    <label for="phone"
                      >Phone Number
                      <span class="form-label-secondary"
                        >(Optional)</span
                      ></label
                    >
                  </div>
                  <!-- End Form -->
                </div>
                <!-- End Col -->
              </div>
              <!-- End Row -->
              <!-- Form -->
              <div
                class="form-floating mb-3"
                :class="{ 'is-invalid': errors.message }"
              >
                <Field
                  name="message"
                  v-slot="{ handleChange, handleBlur, field }"
                  value=""
                >
                  <textarea
                    v-bind="field"
                    class="form-control"
                    id="message"
                    placeholder="Tell us about your project"
                    aria-label="Tell us about your project"
                    style="height: 150px"
                    :class="{ 'is-invalid': errors.message }"
                    @change="handleChange"
                    @blur="handleBlur"
                  ></textarea>
                </Field>
                <label for="message">Message</label>
                <div class="invalid-feedback">{{ errors.message }}</div>
              </div>
              <div style="display: none">
                <label>Title</label
                ><input type="text" name="yourTitle" v-model="yourTitle" />
              </div>
              <!-- End Form -->
              <div class="d-grid">
                <button
                  v-if="!loading"
                  type="submit"
                  class="btn btn-primary btn-lg"
                >
                  Send inquiry
                </button>
                <button
                  v-else
                  class="btn btn-primary btn-lg"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-grow spinner-grow"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Sending...
                </button>
              </div>
            </Form>
            <!-- End Form -->
          </div>
        </div>
        <!-- End Card -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-img-start {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-purple {
  background-color: #9400ff;
}
</style>
